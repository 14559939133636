import React from "react";

import { Container, SEOPage } from "../components";

import { StyledPage } from "../components/css";

export default function Contact() {

  //On page SEO
  const SEO = {
    entity: "page",
    title: "About us",
    keywords: `commercial, office, space`,
    description: `We offer a range of commercial/office space in the Randburg / Strijdom Park area, ranging from 135m2 to more than 1000 m2. In Blairgowrie, we have an office building catering for variable offices from 50m2 to 600m2.`,
    url: `/about-us`,
  }
  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div style={{ padding: "0.5rem 1rem 0 1rem", maxWidth: 1200, margin: "0 auto 2rem auto" }}>
        <h1 style={{ marginBottom: 0 }}>About us</h1>
      </div>

      <div style={{ backgroundImage: "url(https://res.cloudinary.com/bridgeport/image/upload/e_brightness:-20/1bdf9421-02f3-4853-af75-df9c68346bcd-1_o44m6m.jpg)", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>

        <div style={{ backgroundColor: "rgba(255,255,255, 0.7)", padding: "2rem 0" }} >
          <StyledPage>

            <p>Bridgeport Property Administration was formed to address the ever growing demand in the market for a transparent, professional property management company with a focus on tenant relationship management and hands on facilities and property management.</p>
            <p>Founded in 2002 Bridgeport Property Administration has been active in the property market for the last 16 years. The portfolio currently under management is in the region of R1 billion. We manage and represent over ten different landlord companies across the commercial, industrial and retail sectors.</p>
            <p>The portfolio under management is relatively small in size, which has allowed and continues to allow us to build meaningful tenant relationships directly resulting in consistent tenant retention.</p>
            <p>We provide core property management services:</p>
            <ul>
              <li>Leasing – through our extensive network of brokers and existing tenant relationships</li>
              <li>Marketing – for vacant space or asset sales</li>
              <li>Property and facilities management – through our in-house dedicated team</li>
              <li>Accounting, reporting and secretarial – through our in-house dedicated team</li>
              <li>Asset management – portfolio management, asset acquisitions and sales, fund reporting</li>
            </ul>

            <p>Bridgeport Property Administration holds current registration certificates with all the regulatory bodies relevant to managing agents in South Africa: the Estate Agency Affairs Board (“EAAB”) and the National Association of Managing Agents (“NAMA”).</p>
            <p>Bridgeport Property Administration has built its foundation on very strong values that are imbibed in every person engaged with us. The company follows a strict code of conduct as embedded in our core values:</p>

            <ul>
              <li>Meaningful and lasting client relationships – we consider the landlord and the tenant to be our clients and believe a good relationship with both is key to meaningful asset growth</li>
              <li>Professionalism and accountability – open, honest and ethical management of each property is a necessity. We hold ourselves responsible if this is not the case</li>
              <li>Trust and integrity – services that we provide are delivered with the utmost respect and integrity. The expansion and growth of the company comes second to the well trusted relationships we develop with our clients</li>
              <li>Innovation – where possible we will research and seek to implement innovate processes to improve the efficiency and profitability of the portfolio</li>
            </ul>

          </StyledPage>

        </div>

      </div>

    </Container>
  )
}